import React from "react"
import cx from "classnames"

import Layout from "../components/layout"
import * as styles from "../assets/styles/casadesk.module.scss"
import { IMG } from "../const"
import ContactForm from "../components/contact-form"

const Casadesk = () => {
  return (
    <Layout pageTitle="Project Casadesk application for property rental agencies">
      {({
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className="main">
            <section className={styles.casadeskTop}>
              <div className="wrapper">
                <div className={styles.casadeskTopContainer}>
                  <div className={styles.topContent}>
                    <div className={styles.titleContainer}>
                      <svg
                        className={styles.titleBg}
                        width="307"
                        height="60"
                        viewBox="0 0 307 60"
                      >
                        <path
                          fill="#CDF4F8"
                          fillRule="evenodd"
                          d="M77.48 60c40.37 0 48.781-16.429 99.398-23.04C230.177 30 296 42.114 306 20.823 316-.468 238.486 0 155.5 0S10.878 7.533 3.384 19.395C-4.11 31.257-5.506 60 77.481 60z"
                        />
                      </svg>
                      <h1 className={styles.sectionTitle}>Casadesk</h1>
                    </div>
                    <div>
                      <p>
                        Casadesk is an online application that will make life a
                        lot easier for property rental agencies. Not by some
                        magical, revolutionary formula but by cleverly
                        integrating existing technologies into one online
                        system. An online system with which property rental
                        activities will become a fluent movement.
                      </p>
                    </div>
                  </div>
                  <div className={styles.topImgContainer}>
                    <img
                      className={styles.topImg}
                      src={`${IMG.CASADESK}/cas-top-1.svg`}
                      alt="img"
                      loading="lazy"
                      width="1020"
                      height="663"
                    />
                    <img
                      className={styles.topImg1}
                      src={`${IMG.CASADESK}/cas-oval-2.svg`}
                      alt="img"
                      loading="lazy"
                      width="49"
                      height="43"
                    />
                    <img
                      className={styles.topImg2}
                      src={`${IMG.CASADESK}/cas-oval-3.svg`}
                      alt="img"
                      loading="lazy"
                      width="19"
                      height="19"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.casadeskInfo}>
              <div className="wrapper">
                <div className={styles.casadeskInfoContainer}>
                  <ul className={styles.casadeskInfoGrid}>
                    <li className={styles.casadeskInfoItem}>
                      <span className={styles.cInfoYear}>Year</span>{" "}
                      <span>2020</span>
                    </li>
                    <li className={styles.casadeskInfoItem}>
                      <span className={styles.cInfoYear}>Services</span>{" "}
                      <span>Web Development</span>
                    </li>
                    <li className={styles.casadeskInfoItem}>
                      <span className={styles.cInfoYear}>Type</span>{" "}
                      <span>Property rental</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className={styles.casadeskFeatures}>
              <div className={styles.features1}>
                <div className="wrapper">
                  <div className={styles.features1Int}>
                    <div className={styles.featuresContent}>
                      <h2 className={styles.featuresMtitle}>
                        <span>Casadesk</span> Features
                      </h2>
                      <h3 className={styles.featuresTitle}>
                        <b>Properties</b> - complete and easy administration of
                        properties and units
                      </h3>
                      <ul className={styles.featuresList}>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#02C4D9"
                              d="M12.543 3.57c1.553.613 3.311 1.535 4.645 3.246 1.33 1.716 2.24 4.22 1.606 6.142-.634 1.921-2.802 3.261-4.808 4.333-2.01 1.072-3.859 1.875-5.58 1.68-1.714-.196-3.301-1.386-4.63-2.695-1.33-1.314-2.402-2.741-3.026-4.421-.629-1.68-.81-3.612-.734-5.946.072-2.34.4-5.075 1.82-5.74 1.42-.66 3.931.763 5.866 1.623 1.93.86 3.283 1.165 4.84 1.778z"
                            />
                          </svg>
                          <span>
                            A central database with all details on properties,
                            including photos and documentation.
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#14182C"
                              d="M14.434 2.921c1.838 1.384 3.951 2.416 4.445 4.034.494 1.614-.636 3.81-1.389 6.085-.753 2.27-1.129 4.616-2.396 5.507-1.271.886-3.437.317-5.133-.388-1.7-.701-2.927-1.53-4.814-2.2-1.886-.67-4.433-1.177-5.012-2.522-.579-1.345.81-3.523 2.07-5.132C3.462 6.7 4.596 5.663 5.79 4.05 6.985 2.44 8.236.245 9.64.02c1.405-.225 2.96 1.516 4.793 2.901z"
                            />
                          </svg>
                          <span>
                            Logical and directly linked objects as units belong
                            to a property, and properties belong to owners.
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#F7BA5C"
                              d="M12.77 4.942c2.162 1.385 5.003 1.861 5.898 3.021.89 1.16-.163 3.001-1.37 4.508-1.208 1.506-2.562 2.679-4.201 3.709a18.232 18.232 0 01-5.976 2.434c-2.425.522-5.358.68-5.943-.602-.59-1.283 1.164-4.007 1.688-5.741.52-1.73-.191-2.475-1.19-4.247-1-1.77-2.29-4.576-1.35-6.253C1.271.089 4.45-.461 6.783.407c2.332.867 3.823 3.155 5.986 4.535z"
                            />
                          </svg>
                          <span>
                            Easy registration of characteristics through fields
                            (size, number of rooms, bathroom, toilet, etc.) and
                            labels (city center, views, parking place, etc.).
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#12B4BB"
                              d="M5.9 13.19c-1.442-.64-2.38.676-3.62-1.109C1.047 10.291.202 7.678.79 5.673 1.378 3.667 3.39 2.269 5.254 1.15 7.121.033 7.823-.011 9.42.194c1.593.204 1.82 3.34 3.053 4.707 1.235 1.37 4.492.17 5.072 1.922.584 1.753 1.522 3.931 1.451 6.367-.066 2.44-.9 3.602-2.219 4.296-1.318.688-3.891.735-5.687-.163-1.792-.897-3.743-3.493-5.19-4.133z"
                            />
                          </svg>
                          <span>
                            Registration of default rental criteria like monthly
                            rent, deposit, one time fees, notice period.
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#3C171B"
                              d="M17.968 6.269c.518 1.351-.666 3.19-1.455 5.095-.789 1.901 1.384 5.606.056 6.352-1.332.742-3.602.266-5.38-.325-1.781-.587-3.066-1.281-5.043-1.842-1.977-.561-4.702-3.06-5.309-4.185-.606-1.126-1.461-4.08-.142-5.427 1.32-1.344 2.12-3.051 3.371-4.402 1.252-1.348 6.191-3.132 7.35 1.203 1.16 4.335 6.035 2.176 6.552 3.53z"
                            />
                          </svg>
                          <span>
                            Each property has its management fee arrangement.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className={styles.feat1ImgContainer}>
                      <img
                        src={`${IMG.CASADESK}/cas-fut-3.svg`}
                        alt="img"
                        loading="lazy"
                        width="430"
                        height="432"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.features2}>
                <div className="wrapper">
                  <div className={styles.features2Int}>
                    <div className={styles.feat2ImgContainer}>
                      <img
                        className={styles.feat2Img}
                        src={`${IMG.CASADESK}/cas-fut-1.svg`}
                        alt="img"
                        loading="lazy"
                        width="1300"
                        height="920"
                      />
                      <img
                        className={styles.feat21Img}
                        src={`${IMG.CASADESK}/cas-fut-1-1.svg`}
                        alt="img"
                        loading="lazy"
                        width="101"
                        height="85"
                      />
                    </div>
                    <div className={styles.featuresContent}>
                      <h3 className={styles.featuresTitle}>
                        <b>Tenants</b>
                      </h3>
                      <ul className={styles.featuresList}>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#F7BA5C"
                              d="M12.77 4.942c2.162 1.385 5.003 1.861 5.898 3.021.89 1.16-.163 3.001-1.37 4.508-1.208 1.506-2.562 2.679-4.201 3.709a18.232 18.232 0 01-5.976 2.434c-2.425.522-5.358.68-5.943-.602-.59-1.283 1.164-4.007 1.688-5.741.52-1.73-.191-2.475-1.19-4.247-1-1.77-2.29-4.576-1.35-6.253C1.271.089 4.45-.461 6.783.407c2.332.867 3.823 3.155 5.986 4.535z"
                            />
                          </svg>
                          <span>
                            All tenant and contract details, including document
                            scans of the rental contract and identification
                            document.
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#02C4D9"
                              d="M12.543 3.57c1.553.613 3.311 1.535 4.645 3.246 1.33 1.716 2.24 4.22 1.606 6.142-.634 1.921-2.802 3.261-4.808 4.333-2.01 1.072-3.859 1.875-5.58 1.68-1.714-.196-3.301-1.386-4.63-2.695-1.33-1.314-2.402-2.741-3.026-4.421-.629-1.68-.81-3.612-.734-5.946.072-2.34.4-5.075 1.82-5.74 1.42-.66 3.931.763 5.866 1.623 1.93.86 3.283 1.165 4.84 1.778z"
                            />
                          </svg>
                          <span>
                            Tenant directly linked with property unit.
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#14182C"
                              d="M14.434 2.921c1.838 1.384 3.951 2.416 4.445 4.034.494 1.614-.636 3.81-1.389 6.085-.753 2.27-1.129 4.616-2.396 5.507-1.271.886-3.437.317-5.133-.388-1.7-.701-2.927-1.53-4.814-2.2-1.886-.67-4.433-1.177-5.012-2.522-.579-1.345.81-3.523 2.07-5.132C3.462 6.7 4.596 5.663 5.79 4.05 6.985 2.44 8.236.245 9.64.02c1.405-.225 2.96 1.516 4.793 2.901z"
                            />
                          </svg>
                          <span>
                            Timeline with exchanged messages and other relevant
                            events like sent reminders.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className={styles.feat22ImgContainer}>
                      <img
                        className={styles.feat22Img}
                        src={`${IMG.CASADESK}/cas-fut-3.svg`}
                        alt="img"
                        loading="lazy"
                        width="430"
                        height="432"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.features3}>
                <div className="wrapper">
                  <div className={styles.features3Int}>
                    <img
                      className={styles.feat31Img}
                      src={`${IMG.CASADESK}/cas-fut-4-1.svg`}
                      alt="img"
                      loading="lazy"
                      width="106"
                      height="100"
                    />
                    <div className={styles.featuresContent}>
                      <h3 className={styles.featuresTitle}>
                        <b>Financial Management</b> - strict control on incoming
                        and outgoing payments
                      </h3>
                      <ul className={styles.featuresList}>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#14182C"
                              d="M14.434 2.921c1.838 1.384 3.951 2.416 4.445 4.034.494 1.614-.636 3.81-1.389 6.085-.753 2.27-1.129 4.616-2.396 5.507-1.271.886-3.437.317-5.133-.388-1.7-.701-2.927-1.53-4.814-2.2-1.886-.67-4.433-1.177-5.012-2.522-.579-1.345.81-3.523 2.07-5.132C3.462 6.7 4.596 5.663 5.79 4.05 6.985 2.44 8.236.245 9.64.02c1.405-.225 2.96 1.516 4.793 2.901z"
                            />
                          </svg>
                          <span>
                            Automated settlement of income (rent) and costs
                            (management fee, maintenance)
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#F7BA5C"
                              d="M12.77 4.942c2.162 1.385 5.003 1.861 5.898 3.021.89 1.16-.163 3.001-1.37 4.508-1.208 1.506-2.562 2.679-4.201 3.709a18.232 18.232 0 01-5.976 2.434c-2.425.522-5.358.68-5.943-.602-.59-1.283 1.164-4.007 1.688-5.741.52-1.73-.191-2.475-1.19-4.247-1-1.77-2.29-4.576-1.35-6.253C1.271.089 4.45-.461 6.783.407c2.332.867 3.823 3.155 5.986 4.535z"
                            />
                          </svg>
                          <span>
                            Monthly owner statement with detailed incomes,
                            costs, and the net result to be received
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#02C4D9"
                              d="M12.543 3.57c1.553.613 3.311 1.535 4.645 3.246 1.33 1.716 2.24 4.22 1.606 6.142-.634 1.921-2.802 3.261-4.808 4.333-2.01 1.072-3.859 1.875-5.58 1.68-1.714-.196-3.301-1.386-4.63-2.695-1.33-1.314-2.402-2.741-3.026-4.421-.629-1.68-.81-3.612-.734-5.946.072-2.34.4-5.075 1.82-5.74 1.42-.66 3.931.763 5.866 1.623 1.93.86 3.283 1.165 4.84 1.778z"
                            />
                          </svg>
                          <span>
                            Integrated processing of bank statements whereby
                            each incoming and outgoing payment is attributed to
                            the relevant objects
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.features4}>
                <div className="wrapper">
                  <div className={styles.features4Int}>
                    <div className={styles.featuresContent}>
                      <h3 className={styles.featuresTitle}>
                        <b>Incidents</b> - every incident, work order, and
                        status, all visible in one place
                      </h3>
                      <ul className={styles.featuresList}>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#02C4D9"
                              d="M12.543 3.57c1.553.613 3.311 1.535 4.645 3.246 1.33 1.716 2.24 4.22 1.606 6.142-.634 1.921-2.802 3.261-4.808 4.333-2.01 1.072-3.859 1.875-5.58 1.68-1.714-.196-3.301-1.386-4.63-2.695-1.33-1.314-2.402-2.741-3.026-4.421-.629-1.68-.81-3.612-.734-5.946.072-2.34.4-5.075 1.82-5.74 1.42-.66 3.931.763 5.866 1.623 1.93.86 3.283 1.165 4.84 1.778z"
                            />
                          </svg>
                          <span>
                            Each incident is automatically linked with the
                            property unit and tenant, and automated receipt
                            confirmation.
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#F7BA5C"
                              d="M12.77 4.942c2.162 1.385 5.003 1.861 5.898 3.021.89 1.16-.163 3.001-1.37 4.508-1.208 1.506-2.562 2.679-4.201 3.709a18.232 18.232 0 01-5.976 2.434c-2.425.522-5.358.68-5.943-.602-.59-1.283 1.164-4.007 1.688-5.741.52-1.73-.191-2.475-1.19-4.247-1-1.77-2.29-4.576-1.35-6.253C1.271.089 4.45-.461 6.783.407c2.332.867 3.823 3.155 5.986 4.535z"
                            />
                          </svg>
                          <span>
                            Forward the incident with one click to a selected
                            plumber, electrician or carpenter, including all
                            relevant details (problem, address, tenant, phone
                            number).
                          </span>
                        </li>
                        <li className={styles.featuresListItem}>
                          <svg
                            className={styles.svgItem}
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#14182C"
                              d="M14.434 2.921c1.838 1.384 3.951 2.416 4.445 4.034.494 1.614-.636 3.81-1.389 6.085-.753 2.27-1.129 4.616-2.396 5.507-1.271.886-3.437.317-5.133-.388-1.7-.701-2.927-1.53-4.814-2.2-1.886-.67-4.433-1.177-5.012-2.522-.579-1.345.81-3.523 2.07-5.132C3.462 6.7 4.596 5.663 5.79 4.05 6.985 2.44 8.236.245 9.64.02c1.405-.225 2.96 1.516 4.793 2.901z"
                            />
                          </svg>
                          <span>
                            Visible in the dashboard to follow the status.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className={styles.feat4ImgContainer}>
                      <img
                        className={styles.feat4Img}
                        src={`${IMG.CASADESK}/cas-fut-2.svg`}
                        alt="img"
                        loading="lazy"
                        width="750"
                        height="528"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.casadeskStack}>
              <div className="wrapper">
                <h2 className={styles.mtitle}>
                  <span>Technology Stack</span>
                </h2>
                <ul className={styles.stackList}>
                  <li className={cx(styles.stackIt, styles.smw100)}>
                    <h3 className={styles.itName}>Frontend</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/react.svg`}
                        alt="React"
                        title="React"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/redux.svg`}
                        alt="Redux"
                        title="Redux"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/typescript.svg`}
                        alt="TypeScript"
                        title="TypeScript"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                  <li className={styles.stackIt}>
                    <h3 className={styles.itName}>Repository</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/bitbucket.svg`}
                        alt="Bitbucket"
                        title="Bitbucket"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                  <li className={styles.stackIt}>
                    <h3 className={styles.itName}>Architecture</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/html5-txt.svg`}
                        alt="HTML"
                        title="HTML"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                  <li className={styles.stackIt}>
                    <h3 className={styles.itName}>Backend</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/nodejs.svg`}
                        alt="NodeJS"
                        title="NodeJS"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                  <li className={styles.stackIt}>
                    <h3 className={styles.itName}>Database</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/postgresql.svg`}
                        alt="PostgreSQL"
                        title="PostgreSQL"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                  <li className={styles.stackIt}>
                    <h3 className={styles.itName}>Hosting</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/heroku.svg`}
                        alt="Heroku"
                        title="Heroku"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                  <li className={styles.stackIt}>
                    <h3 className={styles.itName}>Search</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/elasticsearch.svg`}
                        alt="Elasticsearch"
                        title="Elasticsearch"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                  <li className={styles.stackIt}>
                    <h3 className={styles.itName}>Translations</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/aksuc.svg`}
                        alt="Aksuc"
                        title="Aksuc"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                  <li className={styles.stackIt}>
                    <h3 className={styles.itName}>EMail platform</h3>
                    <div className={styles.itImgBox}>
                      <img
                        className={styles.itImg}
                        src={`${IMG.ICONS}/logos/postmark-stamp.svg`}
                        alt="Postmark"
                        title="Postmark"
                        loading="lazy"
                        width="56"
                        height="56"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </section>
            <section className={styles.contactForm}>
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default Casadesk
